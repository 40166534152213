<template>
  <v-app>
    <v-app-bar app flat color="deep-purple darken-1" dark>
      <v-spacer />

      <div class="d-flex align-center">
        <v-img
          alt="Bomboo Playground"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
        />
      </div>

      <v-spacer />
    </v-app-bar>

    <v-main>
      <v-layout column align-center style="height: 100%">
        <v-spacer />
        <div class="phone mt-4">
          <div class="phone__content">
            <v-system-bar
              height="24"
              :dark="['home', 'start'].includes($route.name)"
              :color="
                ['home', 'start'].includes($route.name) ? 'transparent' : ''
              "
              class="flex-shrink-0 pt-1"
            >
              Bomboo
              <v-spacer></v-spacer>
              <v-icon>mdi-wifi-strength-4</v-icon>
              <v-icon>mdi-signal-cellular-outline</v-icon>
              <v-icon>mdi-battery</v-icon>
              <span>{{ time }}</span>
            </v-system-bar>
            <div class="flex-grow-1">
              <router-view :date="date" style="height: 100%" />
            </div>
          </div>
        </div>
        <v-spacer />
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
const { DateTime } = require("luxon");

export default {
  name: "App",

  components: {},

  data() {
    return {
      date: new Date(),
      dateTimer: null,
    };
  },

  computed: {
    time() {
      return DateTime.fromJSDate(this.date).toLocaleString(
        DateTime.TIME_SIMPLE
      );
    },
  },

  mounted() {
    if (!this.dateTimer) {
      this.dateTimer = setInterval(() => {
        this.date = new Date();
      });
    }
  },

  beforeDestroy() {
    if (this.dateTimer) {
      clearInterval(this.dateTimer);
    }
  },
};
</script>
<style scoped>
.phone {
  background-image: url("~@/assets/screen.png");
  width: 317px;
  height: 670px;
  padding: 38px 15px 20px 12px;
}

.phone__content {
  background: transparent;
  /* border: 2px solid black; */
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
