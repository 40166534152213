<template>
  <v-layout column class="grey lighten-5">
    <v-spacer />
    <v-flex shrink color="white">
      <div class="text-center py-2 text-h5">
        {{ input }}
      </div>

      <v-divider />

      <v-layout row wrap ma-0>
        <v-flex xs4 v-for="key in keypad" :key="key.text" class="text-center">
          <v-btn icon text x-large @click="onKeyTouch(key)">
            {{ key.text }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-row justify="space-around" class="pa-4 mb-4">
        <v-btn icon v-if="input.length > 0">
          <v-icon> mdi-message-text </v-icon>
        </v-btn>
        <v-btn fab large color="green darken-2" dark @click="onDialTouch">
          <v-icon large> mdi-phone </v-icon>
        </v-btn>
        <v-btn icon v-if="input.length > 0" @click="onBackspaceTouch">
          <v-icon> mdi-backspace </v-icon>
        </v-btn>
      </v-row>

      <v-divider />

      <v-row justify="space-around" class="pa-2 mb-1 mt-1">
        <v-btn icon :to="{ name: 'home' }">
          <v-icon large> mdi-home </v-icon>
        </v-btn>
      </v-row>
    </v-flex>

    <v-dialog
      v-model="dialogActive"
      persistent
      transition="dialog-bottom-transition"
      max-width="280"
    >
      <v-card v-if="loading">
        <v-card-text class="pt-4">
          <v-progress-circular :size="24" color="primary" indeterminate />
          <span class="ml-4">USSD code running</span>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-card-text class="ma-0 py-4 pb-0">
          <div v-html="$options.filters.nl2br(ussdResponse.title)"></div>
          <div class="mt-2" v-if="ussdResponse.type === 'choices'">
            <div v-for="choice in ussdResponse.choices[currentIndex]" :key="choice.value">
              {{ choice.value }}
              {{ choice.text }}
            </div>
          </div>
        </v-card-text>
        <v-card-text v-if="ussdResponse.type !== 'message'" class="pb-2">
          <v-text-field v-model="input" hide-details="" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <template v-if="ussdResponse.type === 'message'">
            <v-btn text @click="dialogActive = false">OK</v-btn>
          </template>
          <template v-else>
            <v-btn text @click="dialogActive = false">Cancel</v-btn>
            <v-spacer />
            <v-btn text @click="onSendClicked">Send</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="floatingKeybordActive" inset>
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="floatingKeybordActive = !floatingKeybordActive"
        >
          close
        </v-btn>
        <div class="my-3">This is a bottom sheet using the inset prop</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-layout>
</template>

<script>
import axios from "axios";
function xmlToJson(xml) {
  // Create the return object
  var obj = {};

  if (xml.nodeType == 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

const http = axios.create({
  baseURL: "/api",
  headers: {
    Accept: "application/xml",
  },
});

export default {
  data() {
    return {
      currentIndex:0,
      maxElementByScreen : 8,
      maxMessageSize : 20,
      keypad: [
        { text: "1", subtitle: "~" },
        { text: "2", subtitle: "ABC" },
        { text: "3", subtitle: "DEF" },
        { text: "4", subtitle: "GHI" },
        { text: "5", subtitle: "JKL" },
        { text: "6", subtitle: "MNO" },
        { text: "7", subtitle: "PQRS" },
        { text: "8", subtitle: "TUV" },
        { text: "9", subtitle: "WXYZ" },
        { text: "*", subtitle: "" },
        { text: "0", subtitle: "+" },
        { text: "#", subtitle: "" },
      ],
      input: "",
      ussdResponse: {
        type: "message",
        message: "",
      },
      session: {
        id: "",
        msidsn: "",
        sc: "",
      },
      loading: false,
      dialogActive: false,
      floatingKeybordActive: false,
    };
  },

  created() {
    this.session.msidsn = "229" + window.localStorage.getItem("phone");
  },

  filters: {
    nl2br(str, isXhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }

      const breakTag =
        isXhtml || typeof isXhtml === "undefined" ? "<br " + "/>" : "<br>";
      return (str + "").replace(/(\r\n|\n\r|\r|\n)/g, breakTag + "$1");
    },
  },

  methods: {
    startSession() {
      this.session.id = "_" + Math.random().toString(36).substr(2, 9);
    },

    onKeyTouch(key) {
      this.input += key.text;
    },

    onBackspaceTouch() {
      this.input = this.input.substring(0, this.input.length - 1);
    },

    onDialTouch() {
      if (!this.input) {
        return;
      }

      this.ussdResponse = {
        type: "",
        message: "",
      };

      if (!this.input.startsWith("*616")) {
        this.loading = true;
        this.dialogActive = true;

        setTimeout(() => {
          this.loading = false;
          this.ussdResponse.type = "message";
          this.ussdResponse.title = "Connection problem or invalid MMI code";
        }, 2000);
      } else {
        this.startSession();
        this.session.sc = "616";

        let userInput = this.input.substring(4).replace("#", "");
        if (userInput.startsWith("*")) {
          userInput = userInput.substring(1);
        }

        this.loading = true;
        this.dialogActive = true;

        this.sendRequest(userInput);
      }

      this.input = "";
      //   this.floatingKeybordActive = true;
    },

    onSendClicked() {
      console.log("the input",this.input);
      if(this.input == "#")
        this.currentIndex += 1
      else if(this.input == "*")
        this.currentIndex -= 1
      else{
        this.currentIndex = 0
        this.loading = true;
        this.sendRequest(this.input);
      }
      this.input = "";
    },

    getChoiceElements(data){
      console.log("data",data);
      let choiceList = []
      let choices = []
      for(let i = 0; i < data.length ; ++i){
        choices.push(data[i])
        console.log(i);
        if(i != 0 && (i + 1) % this.maxElementByScreen == 0 && i < data.length - 1){
          if(choiceList.length != 0){
            choices.push({
              text: 'Précedent',
              value: '*.',
            })
          }
          choices.push({
              text: 'Suivant',
              value: '#.',
            })
          choiceList.push(choices)
          choices = []
        }
        else if(i == data.length - 1){
          if(choiceList.length != 0)
            choices.push({
              text: 'Précedent',
              value: '*.',
            })
            choiceList.push(choices)
        }
          
      }
      return choiceList
    },

    // getMessageElements(data){
    //   let elements = data.split(" ")
    //   position = 0
    //   messageList = []
    //   while(position < elements.length){
    //     messageList.add(elements.splice(position,position + this.maxMessageSize))
    //   }
    //   console.log("messge list",messageList);
    // },


    handleUssdResponse(response) {
      console.log(response);
      this.ussdResponse.type = "";
      this.ussdResponse.title = response.text["#text"];

      switch (response.screen_type["#text"]) {
        case "menu":
          this.ussdResponse.type = "choices";
          this.ussdResponse.choices = [];
          if (Array.isArray(response.options.option)) {
            this.ussdResponse.choices = response.options.option.map(
              (option) => ({
                text: option["#text"],
                value: option["@attributes"].choice,
              })
            );

          } else if (response.options.option) {
            this.ussdResponse.choices = [
              {
                text: response.options.option["#text"],
                value: response.options.option["@attributes"].choice,
              },
            ];
          }
          this.ussdResponse.choices = this.getChoiceElements(this.ussdResponse.choices)
          console.log("this is response choices",this.ussdResponse.choices);
          break;
        case "form":
          if (response.session_op["#text"] === "end") {
            this.ussdResponse.type = "message";
          } else {
            this.ussdResponse.type = "prompt";
          }
          break;
      }
    },

    sendRequest(input) {
      http
        .get("/sandbox", {
          params: {
            msisdn: this.session.msidsn,
            session_id: this.session.id,
            user_input: input,
          },
        })
        .then((response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response.data, "application/xml");
          const json = xmlToJson(xml);

          this.handleUssdResponse(json.response);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
