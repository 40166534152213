<template>
  <v-layout column>
    <v-flex shrink mt-5 pt-5 class="text-h5 text-center white--text">
      {{ dateString }}
    </v-flex>
    <v-spacer />
    <v-flex shrink>
      <v-row justify="space-around" class="pa-4 mb-4">
        <v-btn icon x-large color="white" :to="{ name: 'dialer' }">
          <v-icon large> mdi-phone </v-icon>
        </v-btn>
        <v-btn icon x-large color="white">
          <v-icon large> mdi-message-text </v-icon>
        </v-btn>
        <v-btn icon x-large color="white">
          <v-icon large> mdi-contacts </v-icon>
        </v-btn>
      </v-row>
    </v-flex>
  </v-layout>
</template>

<script>
const { DateTime } = require("luxon");

export default {
  name: "Home",
  components: {},
  props: {
    date: {
      type: Date,
      required: true,
    },
  },

  computed: {
    dateString() {
      return DateTime.fromJSDate(this.date).toFormat("EEEE, MMM d");
    },
  },
};
</script>
