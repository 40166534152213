<template>
  <v-layout column align-center class="grey darken-4">
    <v-spacer />
    <v-form style="width: 100%" class="px-5">
      <v-text-field
        v-model="phone"
        block
        label="Enter phone number"
        mask="## ## ## ##"
        prefix="+229"
        solo
      />
      <v-btn block color="deep-purple" dark @click="onSubmit"> Connect </v-btn>
    </v-form>
    <v-spacer />
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
    };
  },

  methods: {
    onSubmit() {
      if (!this.phone) {
        return;
      }

      window.localStorage.setItem("phone", this.phone);

      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>
